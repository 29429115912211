import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Initialize isAuthenticated from localStorage
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  const [showLogin, setShowLogin] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [windowLocationUrl, setWindowLocationUrl] = useState("");
  const [subVarientIdGlobal, setSubVarientIdGlobal] = useState(() => { return localStorage.getItem('subId') });
  const [userData, setUserData] = useState({});
  const [selectedCardData, setSelectedCardData] = useState(() => {
    const savedCard = localStorage.getItem('selectedCard');
    return savedCard ? JSON.parse(savedCard) : {};
  });
  const [iscardChange, setIsCardChange] = useState(false);
  // Initialize userLocation from localStorage (if available)
  const [userLocation, setUserLocation] = useState(() => {
    const storedLocation = localStorage.getItem('userLocation');
    return storedLocation ? JSON.parse(storedLocation) : { latitude: null, longitude: null };
  });

  const storedToken = localStorage.getItem('token');

  useEffect(() => {
    if (storedToken) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [storedToken]);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    localStorage.setItem('selectedCard', JSON.stringify(selectedCardData));
  }, [selectedCardData]);

  useEffect(() => {
    localStorage.setItem('subId', JSON.stringify(subVarientIdGlobal));
  }, [subVarientIdGlobal]);

  // Update localStorage whenever userLocation changes
  useEffect(() => {
    if (userLocation.latitude && userLocation.longitude) {
      localStorage.setItem('userLocation', JSON.stringify(userLocation));
    }
  }, [userLocation]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.setItem('subId', 0);
    localStorage.removeItem('selectedCard', {});
    localStorage.removeItem('userLocation');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        subVarientIdGlobal, setUserData, userData, setSubVarientIdGlobal, isAuthenticated, setCardInfo, setIsCardChange, iscardChange, cardInfo, login, logout, showLogin, setShowLogin, setUserLocation, userLocation, selectedCardData, setSelectedCardData, setWindowLocationUrl, windowLocationUrl
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};